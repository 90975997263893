import React from 'react';

import Layout from '../components/Layout';

const IndexPage = () => (
  <Layout fullMenu>
    <article id="main">
      <header>
        <h2>My Story</h2>
        <p>Hello there! Welcome to my portfolio!</p>
      </header>
      <section className="wrapper style5">
        <div className="inner">
          <h3>A brief overview</h3>
          <p>
            My name is Karina and I’m a UX designer and Anthropologist. I was
            born and raised in Miami, FL and recently moved to New Orleans, LA.
            At 19, I began my studies as an Anthropologist in Miami Dade College
            and finished at Florida Atlantic University. I was fortunate enough
            to study abroad in Italy and participate in an archaeological field
            school in Ecuador. While my love for Anthropology is strong, I
            realized this career path did not align with my life goals. So I
            sought new opportunities, and as I struggled to find my calling, I
            was introduced to UX/UI design. I was intrigued with the innovative
            and problem-solving nature of this field, which led me to pursue a
            diploma in UX design at BrainStation. I’m now expected to graduate
            at the end of July, 2021 from the program, and I look forward to
            creating connections through human centered design!
          </p>

          <hr />

          <h4>Interests</h4>
          <p>
            Inclusivity/Accessibility, Anthropology, Archaeology, Art, Nature,
            Travel, Anime, Animals
          </p>
          <hr />
          <h4>Skills</h4>
          <p>
            Figma, Zeplin, InVision, Wix, Adobe XD, POP App, UX/UI Design,
            Prototyping, Wireframing, Information Architecture, Information
            Hierarchy, Task Flow Analysis, User Research & Testing, Concept
            Development, Heuristic Evaluation
          </p>
        </div>
      </section>
    </article>
  </Layout>
);

export default IndexPage;
